import React from "react"
import Layout from "../../components/Layout"
import GDPRComponent from "../../components/GDPR"
import SEO from "../../components/seo"

export default function GDPR() {
  return (
    <Layout headerType="education">
      <SEO title="Compulease · GDPR Education" />
      <GDPRComponent />
    </Layout>
  )
}
